import { observable, action, makeAutoObservable, makeObservable, computed } from "mobx";
import { set as _set, uniqueId } from "lodash-es";
import { get as _get } from "lodash-es";
import { IModel } from "./IModel";
import { InputValuesDirty, InputValuesErrors, InputValuesValidity } from "./IModel";
import { getParentObjectPath, isDate, isNullOrUndefined } from "../Utils/Utils";
import { FieldType } from "../Models";
import { ViewModelBase } from "../ViewModels";
import { CoreStoreInstance } from "../Stores";
import { Guid } from "../Utils/Guid";
import _ from "lodash";

export abstract class ModelBase<TModel = any> implements IModel<TModel> {
    public KEY: string = uniqueId();

    public createdDate: Date | null = null;
    public createdById: string | null = null;
    public updatedDate: Date | null = null;
    public updatedById: string | null = null;
    public isDeleted: boolean = false;
    public isDeletedById: string | null = null;
    public isDeletedDate: Date | null = null;

    public Errors = {} as InputValuesErrors<TModel>;
    public Valid = {} as InputValuesValidity<TModel>;
    public Dirty = {} as InputValuesDirty<TModel>;
    //@observable public Touched = {} as InputValuesTouched<T>;

    private GUIDpattern = new RegExp("^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$", "i");

    constructor() {
        makeObservable(this, {
            Errors: observable,
            Valid: observable,
            Dirty: observable,
            toModel: action,
            safeSetValue: action,
            setValue: action,
            setError: action,
            setValid: action,
            setDirty: action,
            createdDate: observable,
            createdById: observable,
            updatedDate: observable,
            updatedById: observable,
            isDeleted: observable,
            isDeletedById: observable,
            isDeletedDate: observable,

            // getError: action,
            // getDirty: action,
            // getValue: action,
            // getValid: action,
        });

        //Loop through added properties setting their default values
        for (let prop in this) {
            if (prop != "Errors" && prop != "Valid" && prop != "Dirty") {
                // @ts-ignore
                this.Errors[prop] = "";
                // @ts-ignore
                this.Valid[prop] = true;
                // @ts-ignore
                this.Dirty[prop] = false;
                // @ts-ignore
                //this.Touched[prop] = false;
            }
        }
    }

    //https://github.com/typestack/class-transformer#working-with-nested-objects

    toModel(object: any): void {
        for (let key in object) {
            if (this.hasOwnProperty(key)) {
                const isDate = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)((-(\d{2}):(\d{2})|Z)?)$/.test(object[key]);
                if (isDate) {
                    this[key as any] = new Date(object[key]);
                } else if (this[key] && this[key as any].remove) {
                    //Do nothing as this is an obervable array
                    //this[key].replace(object[key]);
                } else {
                    this[key] = object[key];
                }
            }
        }
    }

    fromResponse(response: any): void {
        this.toModel(response);
    }

    toRequest<TRequest>(request: TRequest, trim: boolean = true): void {
        for (let key in request) {
            let doesNotExist = isNullOrUndefined((this as any)[key]);
            if (doesNotExist) {
                console.warn(`WARNING: ModelBase.fromModel: ${key} does not exist on model ${this.constructor.name}`);
            } else {
                if ((this as any)[key] instanceof ModelBase === false) {
                    if ((this as any)[key].toISOString) {
                        (request as any)[key] = (this as any)[key].toISOString(true);
                    } else if ((this as any)[key] && (this as any)[key].remove) {
                        //Do nothing as this is an obervable array
                        CoreStoreInstance.coreLogger.logDebug("Skipping observable array");
                    } else {
                        (request as any)[key] = (this as any)[key];
                    }
                    if (typeof (request as any)[key] === "string") {
                        //EN: Exclude null characters in a string
                        ((request as any)[key] as any as string).replace(/\0/g, "");
                        ((request as any)[key] as any as string) = ((request as any)[key] as any as string).trim();
                    }
                }
            }
        }
    }

    public cloneModel(): ModelBase<TModel> {
        return _.cloneDeep(this);
    }

    public safeSetValue(fieldName: any, value: any) {
        if (window.IE11) {
            (this as any)[fieldName] = value;
        } else {
            _set(this, fieldName as any, value);
        }
    }

    public setValue<TR>(fieldName: keyof FieldType<TModel>, value: TR): void {
        this.safeSetValue(fieldName as any, value);
    }

    public setError(fieldName: keyof FieldType<TModel>, value: string): void {
        let path = getParentObjectPath(fieldName as any, "Errors");
        this.safeSetValue(path, value);
    }

    public setValid(fieldName: keyof FieldType<TModel>, value: boolean): void {
        let path = getParentObjectPath(fieldName as any, "Valid");
        this.safeSetValue(path, value);
    }

    public setDirty(fieldName: keyof FieldType<TModel>, value: boolean): void {
        let path = getParentObjectPath(fieldName as any, "Dirty");
        this.safeSetValue(path, value);
    }

    // public getValue<TR>(fieldName: keyof FieldType<T>): TR {
    //     return _get(this, fieldName as any);
    // }

    // public getValid(fieldName: keyof FieldType<T>): boolean {
    //     let path = getParentObjectPath(fieldName as any, "Valid");
    //     return _get(this, path);
    // }
    // public getError(fieldName: keyof FieldType<T>): string {
    //     let path = getParentObjectPath(fieldName as any, "Errors");
    //     return _get(this, path);
    // }

    // public getDirty(fieldName: keyof FieldType<T>): boolean {
    //     let path = getParentObjectPath(fieldName as any, "Dirty");
    //     return _get(this, path);
    // }

    // @action
    // public setTouched(fieldName: keyof FieldType<T>, value: boolean): void {
    //     let path = getParentObjectPath(fieldName as any, "Touched");
    //     this.safeSetValue(path, value);
    // }

    // public getTouched(fieldName: keyof FieldType<T>): boolean {
    //     let path = getParentObjectPath(fieldName as any, "Touched");
    //     return _get(this, path);
    // }
}
