import * as History from "history";
import "url-search-params-polyfill";
import { getPath } from "ts-object-path";
import { CoreStoreInstance } from "../Stores";
import AsyncStorage from "@react-native-async-storage/async-storage";
import _ from "lodash";

const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";

export const nameofFactory =
    <T>() =>
    (name: keyof T) =>
        name;

export const _isNil = function (value: any) {
    return value == null;
};

export const getRememberMeFlag = (): boolean => {
    return localStorage.getItem("rememberMe") !== null;
};

export const getJWT = async (): Promise<string> => {
    const rememberMe = getRememberMeFlag();
    if (CoreStoreInstance.CoreOptions && CoreStoreInstance.CoreOptions.useAsyncStorage) {
        //console.log("getJWT from AsyncStorage");
        return (await AsyncStorage.getItem(".auth")) as string;
    } else if (CoreStoreInstance.CoreOptions.useSessionStorage || !rememberMe) {
        //console.log("getJWT from SessionStorage");
        let jwt = sessionStorage.getItem(".auth") as string;
        return Promise.resolve(jwt);
    } else if (localStorage) {
        //console.log("getJWT from LocalStorage");
        return Promise.resolve(localStorage.getItem(".auth") ?? "");
    }
    return Promise.resolve("");
};

export const setJWT = async (jwt: string | undefined): Promise<void> => {
    const rememberMe = getRememberMeFlag();
    if (CoreStoreInstance.CoreOptions && CoreStoreInstance.CoreOptions.useAsyncStorage) {
        //console.log("setJWT from AsyncStorage");
        await AsyncStorage.setItem(".auth", jwt as string);
    } else if (CoreStoreInstance.CoreOptions.useSessionStorage || !rememberMe) {
        //console.log("setJWT from SessionStorage");
        sessionStorage.setItem(".auth", jwt as string);
        return Promise.resolve();
    } else if (localStorage) {
        //console.log("setJWT from LocalStorage");
        localStorage.setItem(".auth", jwt as string);
        return Promise.resolve();
    }
};

export const deleteJWT = async (): Promise<void> => {
    if (CoreStoreInstance.CoreOptions.useAsyncStorage) {
        await AsyncStorage.removeItem(".auth");
    } else if (CoreStoreInstance.CoreOptions.useSessionStorage) {
        sessionStorage.removeItem(".auth");
        localStorage.removeItem(".auth");
        return Promise.resolve();
    } else if (localStorage) {
        localStorage.removeItem(".auth");
        sessionStorage.removeItem(".auth");
        return Promise.resolve();
    }
};

export const isJWTValid = async (): Promise<boolean> => {
    let jwt = await getJWT();
    if (isNullOrEmpty(jwt)) {
        return false;
    }
    let decodedJwt: any = parseJwt(jwt);
    if (isNullOrEmpty(decodedJwt)) {
        return Promise.resolve(false);
    } else {
        if (Date.now() >= decodedJwt.exp * 1000) {
            return Promise.resolve(false);
        }
    }
    return Promise.resolve(true);
};

export const parseJwt = (token: string) => {
    let retval = "";
    try {
        const base64Url = token.split(".")[1];
        let fixedBase64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
        switch (fixedBase64.length % 4) {
            case 0:
                break;
            case 2:
                fixedBase64 += "==";
                break;
            case 3:
                fixedBase64 += "=";
                break;
            default:
                throw "Illegal base64url string!";
        }
        const base64 = decodeURIComponent(
            window.atob(fixedBase64).replace(/(.)/g, function (m, p) {
                let code = p.charCodeAt(0).toString(16).toUpperCase();
                if (code.length < 2) {
                    code = "0" + code;
                }
                return "%" + code;
            }),
        );
        retval = JSON.parse(base64);
    } catch (ex) {
        CoreStoreInstance.coreLogger.logDebug("Httpclient exception", ex);
    }
    return retval;
};

export const atob = (input: string = "") => {
    let str = input.replace(/[=]+$/, "");
    let output = "";

    if (str.length % 4 == 1) {
        throw new Error("'atob' failed: The string to be decoded is not correctly encoded.");
    }
    for (
        let bc = 0, bs = 0, buffer, i = 0;
        (buffer = str.charAt(i++));
        ~buffer && ((bs = bc % 4 ? bs * 64 + buffer : buffer), bc++ % 4) ? (output += String.fromCharCode(255 & (bs >> ((-2 * bc) & 6)))) : 0
    ) {
        buffer = chars.indexOf(buffer);
    }

    return output;
};

export const btoa = (input: string = "") => {
    let str = input;
    let output = "";

    for (let block = 0, charCode, i = 0, map = chars; str.charAt(i | 0) || ((map = "="), i % 1); output += map.charAt(63 & (block >> (8 - (i % 1) * 8)))) {
        charCode = str.charCodeAt((i += 3 / 4));

        if (charCode > 0xff) {
            throw new Error("'btoa' failed: The string to be encoded contains characters outside of the Latin1 range.");
        }

        block = (block << 8) | charCode;
    }

    return output;
};

export const getHistory = (): History.History => {
    return CoreStoreInstance.GlobalHistory;
};

export const isDate = (date: string) => {
    let d = new Date(date);
    return !isNaN(d.valueOf());
};

export const getParentObjectPath = (fieldName: string, action: "Errors" | "Valid" | "Dirty"): string[] => {
    let path: string[] = [];
    if (typeof fieldName === "string") {
        path = fieldName.split(".");
    } else {
        path = getPath(fieldName) as string[];
        // (fieldName as any).forEach((key: any) => {
        // 	path.push(key);
        // });
    }
    path.splice(path.length - 1, 0, action);
    return path;
};

export const generateID = function (prefix: string = "") {
    // Math.random should be unique because of its seeding algorithm.
    // Convert it to base 36 (numbers + letters), and grab the first 9 characters
    // after the decimal.
    return stripWhiteSpace(prefix) + "_" + Math.random().toString(36).substr(2, 9);
};

export const stripWhiteSpace = function (str: string) {
    return str.replace(/\s/g, "");
};
/**
 * Test if a string is null, undefined, or empty.
 *
 * @param {string | undefined | null} text
 *
 * @returns true if null, undefined or empty, otherwise false.
 */
export const isNullOrEmpty = (text: string | undefined | null): boolean => {
    return text === undefined || text === null || text.length === 0;
};

/**
 * Test if an object is null or undefined.
 *
 * @param {any} value Object to test.
 */
export function isNullOrUndefined(value: any) {
    return value === null || value === undefined;
}

/**
 * Test if an object is empty.
 *
 * @param {any} value Object to test.
 */
export function isObjectEmpty(value: any) {
    return Object.keys(value).length === 0;
}

/**
 * Test if a string is null, undefined, empty, or whitespace.
 *
 * @param {string | undefined | null} text
 *
 * @returns true if null, undefined, empty, or whitespace otherwise false.
 */
export const isEmptyOrWhitespace = (text: string | undefined | null): boolean => {
    return text === undefined || text === null || text.trim().length < 1;
};

export const sortByString = (a: string | undefined, b: string | undefined, options?: Intl.CollatorOptions) => {
    if (a === undefined && b === undefined) {
        return 0;
    }
    if (a === undefined) {
        return -1;
    }
    if (b === undefined) {
        return 1;
    }

    return a.localeCompare(b, undefined, options);
};

export const coalesce = <TArg>(...args: (TArg | undefined)[]) => {
    for (const arg of args) {
        if (_isNil(arg) === false) {
            return arg;
        }
    }

    return null;
};
export const getApiUrl = (): string => (window as any).apiurl;

export const getImageUrl = (imageUrl: string): string => {
    return getApiUrl() + imageUrl;
};

export const getBaseUrl = (): string => {
    const baseElements = document.getElementsByTagName("base");

    if (baseElements.length === 0) {
        throw new Error("Base element not found");
    }

    if (baseElements.length > 1) {
        throw new Error("Multiple base elements found");
    }

    const baseElement = baseElements[0];
    const baseUrl = baseElement.getAttribute("href");

    if (baseUrl === undefined) {
        throw new Error("Base element 'href' attribute not found.");
    }

    let retVal: string = "";
    if (baseUrl !== null) {
        retVal = baseUrl;
    }
    return retVal;
};

export const getCookie: (cname: string) => string = (cname: string): string => {
    let name: string = cname + "=";
    let decodedCookie: string = decodeURIComponent(document.cookie);
    let ca: string[] = decodedCookie.split(";");
    for (let i: number = 0; i < ca.length; i++) {
        let c: string = ca[i];
        while (c.charAt(0) === " ") {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
};

export const getUrlSearchParams: () => URLSearchParams = () => {
    return new URLSearchParams(window.location.search);
};

export const formatCurrency = (value: number, isoCode = "en-GB", currency = "GBP"): string => {
    // Create our number formatter.
    const formatter = new Intl.NumberFormat(isoCode, {
        style: "currency",
        currency: currency,

        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });

    return formatter.format(value); /* £2,500.00 */
};
